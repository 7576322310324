import Vue from 'vue'
import 'bootstrap/scss/bootstrap.scss'
import "bootstrap";
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss";
import { i18n } from '@/plugins/i18n.js'
import { Trans } from './plugins/Translation'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false
Vue.component('v-select', vSelect)

// router.beforeEach((to, from, next) => {
//   const lang = to.params.lang;
//   console.log(to)
//   console.log(from)
//   console.log(next)
//
//   if ( !['en','ru','uk'].includes(lang) ) {
//     return next('en');
//   }
//
//   if ( i18n.locale !== lang ) {
//     i18n.locale = lang;
//   }
//
//   return next();
// });


new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
