import { Trans } from '@/plugins/Translation'

function load (component) {
  // '@' is aliased to src/components
  return () => import(/* webpackChunkName: "[request]" */ `../pages/${component}.vue`)
}

export default [
  {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'Home',
        component: load('Home')
      },
      {
        path: 'reviews',
        name: 'Reviews',
        component: load('Reviews')
      },
      {
        path: 'cart',
        name: 'Cart',
        component: load('Cart')
      },
      {
        path: 'about',
        name: 'About',
        component: load('About')
      },
      {
        path: 'success',
        name: 'Success',
        component: load('Success')
      },
      {
        path: 'what-is-garcinia',
        name: 'WhatIsGarcinia',
        component: load('WhatIsGarcinia')
      },
      // {
      //   path: '/404',
      //   name: '404',
      //   component: load('NotFound')
      // },
      {
        path: '*',
        component: load('NotFound')
      }
    ],
  },
  {
    // Redirect user to supported lang version.
    path: '*',
    redirect (to) {
      return Trans.getUserSupportedLang(to)
    }
  }
]
