<template>
  <div id="app" v-bind:class="containerClass">
    <HeaderMenu/>
    <router-view/>
    <!-- <Footer v-if="this.$route.path != '/cart'" /> -->
    <Footer v-if="this.$route.name != 'Cart'" />
  </div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  data() {
    return {
      containerClass: 'home',
    }
  },
  components: {
    HeaderMenu: HeaderMenu,
    Footer: Footer
  },
  computed: {
    currentRouteName() {
      // console.log(this.$route.name);
        return this.$route.name;
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
$golden-color: #C9AD81;
body {
  font-family: 'Montserrat', sans-serif;
}
.gold-text {
  color: $golden-color;
}
.page-block {
  min-height: 100vh;
  position: relative;
  > .container {
    position: relative;
    z-index: 1;
  }
}

.text-page-content {
  padding-top: 2em;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
}
.bottom-buy-link {
  text-align: center;
  margin-top:3em;
  margin-bottom: 3em;
}


.main-title {
  text-transform: uppercase;
  color: $golden-color;
  font-size: 1.5em;
  margin-bottom: 1em;
  letter-spacing: 0.1em;
  @media screen and (min-width: 576px) {
    font-size: 3em;
  }
}
.subtitle {
  color: $golden-color;
  font-weight: normal;
  font-size: 1em;
  @media screen and (min-width: 576px) {
    font-size: 1.5em;
  }
}
.small-title, .home-blade-title {
  color: $golden-color;
  font-weight: 600;
  text-transform: uppercase;
}
.small-title {
  font-size: 1.5em;
  letter-spacing: 0.1em;
}
.home-blade-title {
  letter-spacing: 0.04em;
  line-height: 1.25;
  text-align: center;
  padding-bottom: 3rem;
  font-size: 1.2em;
  &.home-blade-title-line {
    padding-bottom: 0;
  }
  @media screen and (min-width: 576px) {
    font-size: 1.5em;
    padding-bottom: 75px;
    &.home-blade-title-line {
      padding-bottom: 0;
    }
  }
}

.button {
  display: inline-block;
  padding: 12px 50px;
  color: $golden-color;
  border: 1px solid rgba(0, 0, 0, 0.5);
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: #fff;
    background: $golden-color;
    cursor: pointer;
    border: 1px solid $golden-color;
  }
}
.buy-button {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $golden-color;
}
// @import '../node_modules/bootstrap/scss/bootstrap.scss';
// // @import '../../../node_modules/fullpage.js/dist/fullpage.min.css';
// @import '../node_modules/swiper/swiper.scss';
// @import '@/assets/scss/style';
// // import 'swiper/swiper-bundle.css'

</style>
