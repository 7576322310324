<template>
  <header class="header-menu">
    <div class="container-fluid">
      <div class="d-flex d-sm-none mobile-navbar">
        <a class="blog-header-logo text-dark" href="/"><img src="/assets/images/logo.png" /></a>
        <button class="mobile-menu-icon" @click="toggleMenu()">
          <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1.5" x2="30" y2="1.5" stroke="#FFC669" stroke-width="3"/>
            <line y1="16.5" x2="30" y2="16.5" stroke="#FFC669" stroke-width="3"/>
            <line y1="31.5" x2="30" y2="31.5" stroke="#FFC669" stroke-width="3"/>
          </svg>
        </button>
      </div>
      <div class="d-none d-sm-flex row flex-nowrap justify-content-between align-items-center">
        <div class="col-4">
          <router-link :to="$i18nRoute({ name: 'Home'})" :class="'menu-item'">{{ $t('menu.home') }}</router-link>
          <router-link :to="$i18nRoute({ name: 'Reviews'})" :class="'menu-item'">{{ $t('menu.reviews') }}</router-link>
        </div>
        <div class="col-4 text-center">
          <a class="blog-header-logo text-dark" href="/"><img src="/assets/images/logo.png" /></a>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-center">
          <router-link :to="$i18nRoute({ name: 'Cart'})" :class="'menu-item'">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.7499 30H5.24994C4.63744 30 4.05494 29.7362 3.65244 29.275C3.24869 28.8137 3.06244 28.2025 3.14369 27.595L5.74369 8.095C5.88369 7.0425 6.78994 6.25 7.84994 6.25H22.1499C23.2112 6.25 24.1162 7.0425 24.2574 8.095L26.8574 27.595C26.9374 28.2025 26.7524 28.815 26.3487 29.275C25.9449 29.7362 25.3624 30 24.7499 30ZM7.84994 7.5C7.41244 7.5 7.04119 7.82625 6.98369 8.25875L4.38369 27.7587C4.34994 28.0125 4.42369 28.2575 4.59244 28.4512C4.76119 28.6437 4.99369 28.75 5.24994 28.75H24.7499C25.0062 28.75 25.2399 28.6437 25.4074 28.4512C25.5762 28.2587 25.6499 28.0125 25.6162 27.7587L23.0162 8.25875C22.9587 7.82625 22.5862 7.5 22.1499 7.5H7.84994Z" fill="#EEEEEE"/>
            <path d="M18.125 10C17.78 10 17.5 9.72 17.5 9.375V3.75C17.5 2.37125 16.3787 1.25 15 1.25C13.6212 1.25 12.5 2.37125 12.5 3.75V9.375C12.5 9.72 12.22 10 11.875 10C11.53 10 11.25 9.72 11.25 9.375V3.75C11.25 1.6825 12.9325 0 15 0C17.0675 0 18.75 1.6825 18.75 3.75V9.375C18.75 9.72 18.47 10 18.125 10Z" fill="#EEEEEE"/>
            </svg>
          </router-link>
          <LanguageListWidget v-bind:class="'desktop-menu-language'" />
        </div>
      </div>
    </div>
    <div class="mobile-menu-area">
      <div class="mobile-menu-header">
        <div class="mobile-menu-close"  @click="toggleMenu()"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 1.41405L22.5859 0L12 10.5859L1.41405 0L0 1.41405L10.5859 12L0 22.5859L1.41405 24L12 13.4141L22.5859 24L24 22.5859L13.4141 12L24 1.41405Z" fill="black" fill-opacity="0.5"/></svg></div>
      </div>
      <router-link @click.native="linkRemoveBodyClass()" :to="$i18nRoute({ name: 'Home'})" :class="'mobile-menu-item'">{{ $t('menu.home') }}</router-link>
      <router-link @click.native="linkRemoveBodyClass()" :to="$i18nRoute({ name: 'About'})" :class="'mobile-menu-item'">{{ $t('menu.about') }}</router-link>
      <router-link @click.native="linkRemoveBodyClass()" :to="$i18nRoute({ name: 'WhatIsGarcinia'})" :class="'mobile-menu-item'">{{ $t('menu.whatIs') }}</router-link>
      <router-link @click.native="linkRemoveBodyClass()" :to="$i18nRoute({ name: 'Reviews'})" :class="'mobile-menu-item'">{{ $t('menu.reviews') }}</router-link>
      <router-link @click.native="linkRemoveBodyClass()" :to="$i18nRoute({ name: 'Cart'})" :class="'mobile-menu-item'">{{ $t('menu.buy') }} <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7499 30H5.24994C4.63744 30 4.05494 29.7362 3.65244 29.275C3.24869 28.8137 3.06244 28.2025 3.14369 27.595L5.74369 8.095C5.88369 7.0425 6.78994 6.25 7.84994 6.25H22.1499C23.2112 6.25 24.1162 7.0425 24.2574 8.095L26.8574 27.595C26.9374 28.2025 26.7524 28.815 26.3487 29.275C25.9449 29.7362 25.3624 30 24.7499 30ZM7.84994 7.5C7.41244 7.5 7.04119 7.82625 6.98369 8.25875L4.38369 27.7587C4.34994 28.0125 4.42369 28.2575 4.59244 28.4512C4.76119 28.6437 4.99369 28.75 5.24994 28.75H24.7499C25.0062 28.75 25.2399 28.6437 25.4074 28.4512C25.5762 28.2587 25.6499 28.0125 25.6162 27.7587L23.0162 8.25875C22.9587 7.82625 22.5862 7.5 22.1499 7.5H7.84994Z" fill="#EEEEEE"/>
      <path d="M18.125 10C17.78 10 17.5 9.72 17.5 9.375V3.75C17.5 2.37125 16.3787 1.25 15 1.25C13.6212 1.25 12.5 2.37125 12.5 3.75V9.375C12.5 9.72 12.22 10 11.875 10C11.53 10 11.25 9.72 11.25 9.375V3.75C11.25 1.6825 12.9325 0 15 0C17.0675 0 18.75 1.6825 18.75 3.75V9.375C18.75 9.72 18.47 10 18.125 10Z" fill="#EEEEEE"/>
      </svg></router-link>
      <LanguageListWidget v-bind:class="'mobile-menu-language'" />
    </div>
  </header>
</template>

<script>
import LanguageListWidget from '@/components/LanguageListWidget'

export default {
  name: 'HelloWorld',
  components: {
    LanguageListWidget
  },
  data() {
    return {
      langs: {en: 'ENG', ru: 'РУС', uk: 'УКР'},
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
      this.setBodyClass()
    },
    setBodyClass() {
      var body = document.body
      body.classList.toggle('mobile-menu-active')
    },
    linkRemoveBodyClass() {
      var body = document.body
      body.classList.remove('mobile-menu-active')
    }
  }
}
</script>

<style lang="scss">
$golden-color: #C9AD81;
.header-menu {
  position: fixed;
  top: 0px;
  width: 100%;
  padding: 15px;
  background: #000;
  z-index: 9;
}
.desktop-menu-language {
  display: flex;
  justify-content: center;
}
.menu-item, .desktop-menu-language a {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #fff;
  margin: 15px;
  text-decoration: none;
  &:hover, &.active, &.router-link-exact-active {
    color: #FFC669;
    svg {
      path {
        fill: $golden-color;
      }
    }
  }
}
.page-content {
  margin-top: 90px;
}

.mobile-navbar {
  display: flex;
  justify-content: space-between!important;
}
.mobile-menu-area {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #fff;
  transform: translateX(-100vw);
  transition: transform 1s;
  body.mobile-menu-active & {
    transform: translateX(0);
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.mobile-menu-item {
  display: flex;
  font-weight: 500;
  padding: 1em;
  color: rgba(0, 0, 0, 0.75);
  svg {
    margin-left: 1em;
  }
}
.mobile-menu-item, .mobile-menu-language a {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}
.mobile-menu-language {
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.mobile-menu-language a {
  font-weight: 600;
  padding: 1em;
}
.mobile-menu-header {
  display: flex;
  justify-content: flex-end;
}
.mobile-menu-close {
  padding: 1em;

}
button.mobile-menu-icon {
  padding: 0;
  border: none;
  background: none;
  &:focus { outline: none; }
}
</style>
